<template>
  <div class="register">
    <img src="../../../../assets/pc/images/login/loginLogo.png" alt="" />
    <!--    右侧头部区域-->
    <div class="login-right-top flex">
      <span>设置密码</span>
      <span>首次登录请先设置登录密码</span>
    </div>
    <!--    右侧表单区域-->
    <div class="login-form">
      <el-form
        ref="registerFormRef"
        :model="registerForm"
        :rules="registerFormRules"
      >
        <el-form-item prop="name" style="display: none">
          <!-- <span>手机号</span> -->
          <!-- <el-input
            v-model="registerForm.name"
            placeholder="请输入联系人姓名"
            @keyup.native="restempty('registerForm', 'name')"
            maxlength="5"
          ></el-input> -->
        </el-form-item>
        <el-form-item prop="phoneNumber" style="display: none">
          <!-- <span>手机号</span>
          <el-input
            v-model="registerForm.phoneNumber"
            placeholder="请输入手机号码"
            maxlength="11"
            @keyup.native="restempty('registerForm', 'phoneNumber')"
          ></el-input> -->
        </el-form-item>
        <el-form-item prop="code" style="display: none">
          <!-- <div>短信验证码</div>
          <el-input
            v-model="registerForm.code"
            placeholder="请输入5位验证码"
            @keyup.native="restempty('registerForm', 'code')"
            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
            maxlength="5"
          ></el-input>
          <el-button
            plain
            class="get-ver"
            @click="getVerfiCode"
            :disabled="disabled"
            >{{ button_label }}</el-button
          > -->
        </el-form-item>
        <el-form-item prop="password">
          <div>登录密码</div>
          <el-input
            v-model="registerForm.password"
            auto-complete="new-password"
            type="password"
            placeholder="请输入密码(6-20位，须含数字和字母)"
            @keyup.native="restempty('registerForm', 'password')"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item prop="passwordAgain">
          <div>确认密码</div>
          <el-input
            v-model="registerForm.passwordAgain"
            type="password"
            :disabled="isNotAgainAble"
            placeholder="请再次输入密码"
            @keyup.native="restempty('registerForm', 'passwordAgain')"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <el-form-item id="checkbox-form">
          <el-checkbox v-model="keepWake">我已阅读并同意</el-checkbox>
          <span style="color: #156ED0" @click="dialogVisible = true"
            >《政策服务平台服务及隐私协议》
          </span>
          <div
            style="color: #f56c6c; margin-top: 10px; font-size: 12px"
            v-if="keepWaketext"
          >
            请勾选同意服务及隐私协议
          </div>
          <div style="margin-top: 10px" v-else></div>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            class="submit-btn"
            @click="quickRegis"
            :class="{ 'check-box-click': !keepWake }"
            >注册</el-button
          >
          <!--        <el-button plain class="submit-btn-continue"-->
          <!--                   @click="regisAndCom">注册并完善信息</el-button>-->
        </el-form-item>
      </el-form>
    </div>
    <el-dialog title="" :visible.sync="dialogVisible" width="80%">
      <div style="font-size: 20px; letter-spacing: 2px">
        <h1 style="text-align: center">政策服务云平台用户协议</h1>
        <p>
          欢迎您使用我们的平台！本政策服务云平台用户协议（下称“本协议”）是您在使用政策服务云平台（www.po-o.cn）服务时所应遵守的条款及条件。本协议由广东政沣云技术有限公司（下称“政沣公司”）与您所代表的实体（下称“用户”或“您”）签订。您向我们声明并保证，您具有合法代表该实体之权限且有可约束该实体遵守本协议条款与条件之合法权限。本协议自您接受之日起生效。请您务必审慎阅读、充分理解本协议内容。如您对本协议有任何疑问，可咨询客服。如您不同意本协议中任何条款，请立即停止注册。
        </p>
        <h2>第一条 定义</h2>
        <p>1.协议：是指《政策服务云平台用户服务及隐私协议》，亦称本协议。</p>
        <p>
          2.平台：是指政策服务云平台及相关子系统，即域名为www.po-o.cn的网站及其用户端。
        </p>
        <p>
          3.注册：是指登记验证手机号码、设置账号密码、确认同意协议，开通平台操作使用账号。
        </p>
        <p>4.账号：是指手机号码（用户名）+密码。</p>
        <p>
          5.用户：是指平台的操作使用者，包括游客、普通用户、会员、服务机构等类型。
        </p>
        <p>6.游客：是指未注册的用户。 7.普通用户：是指已注册但未付费的用户。</p>
        <p>7.会员：是指已注册且付费的用户。</p>
        <p>
          8.服务机构赋能系统：是指平台供服务机构专用的一种赋能型软件和数据服务子系统。
        </p>
        <p>
          9.服务机构：是指咨询、专利、审计、认证、会展、软件、人才等方面服务提供商。
        </p>
        <h2>第二条 概述</h2>
        <p>
          1.本协议旨在保障平台用户的合法权益，创建、维护和谐的网络商业环境。
        </p>
        <p>2.平台的所有权和运营权归政沣公司。</p>
        <p>3.平台所有者有权依据本协议制定相关的产品或服务管理细则。</p>
        <p>4.平台实行实名制，平台有权对用户身份的真实性进行核查。</p>
        <p>
          5.在用户开通或使用某项服务时，可能会涉及某项服务的单独协议，所有单独协议为本协议不可分割的组成部分，与协议正文具有同等法律效力。
        </p>
        <p>
          6.平台有权随时根据有关法律、法规的变化，以及公司经营状况和经营策略的调整等修订本协议。修订内容将通过平台公示。如您不同意修订内容，您可以主动取消已经获得的服务；如您继续享用服务，则视为您已经接受修订内容。当您使用平台的任何服务时，应接受平台随时提供的与该服务相关的规则或说明，并且此规则或说明均视作本协议的一部分。
        </p>
        <p>7.当发生争议时，以争议事项、争议行为发生时的协议版本为准。</p>
        <h2>第三条 注册</h2>
        <p>
          1.用户应自行向平台提供注册资料，并保证注册资料真实、准确、完整、有效，注册资料如有变动的，应及时更新。如注册资料不真实、不准确、不完整、失效，影响用户使用感知，平台不承担相应责任及后果，如用户因此提出恶意投诉或索赔，平台有权拒绝处理。
        </p>
        <p>
          2.账号密码由用户自行设置和保管，用户应谨慎妥善保管，原则上不得透露密码给他人。如发现密码被盗，用户应当立即以有效方式通知平台，申请暂停相关服务。
        </p>
        <p>
          3.平台会不定期对用户身份的真实性进行确认，该确认行为不会单独另行通知。对于未通过确认的账号，平台有权冻结或注销。
        </p>
        <p>
          4.平台账号的所有权归平台所有，用户完成注册手续后，仅获得平台账号的使用权。
        </p>
        <h2>第四条 平台使用规范 1.在使用平台过程中，您承诺遵守以下约定：</h2>
        <p>
          (1)遵守国家法律法规及本协议，不违背社会公德，不损害他人的合法权益。
        </p>
        <p>
          (2)不利用平台软件、内容或数据做任何有损于平台的事，包括但不限于以经营同类产品营利为目的，抄袭、复制、转发、传播平台软件、内容或数据。
        </p>
        <p>(3)不使用任何装置、设备、软件或程序干扰或试图干扰平台的正常运行。</p>
        <p>(4)不上传带有计算机病毒的文档或数据。</p>
        <h2>2.您了解并同意：</h2>
        <p>
          (1)平台有权对您是否违反上述承诺做出单方认定，并根据单方认定结果适用规则予以处理或终止向您提供服务，且无须征得您的同意或提前通知您。
        </p>
        <p>
          (2)经国家行政或司法机关的生效法律文书确认您存在违法或侵权行为，或平台根据自身的判断，认为您的行为涉嫌违反本协议和/或相关规则的条款或涉嫌违反法律法规的规定的，则政沣公司有权在平台上公示您的该等涉嫌违法或违约行为及已对您采取的措施。
        </p>
        <p>
          (3)违反上述承诺产生法律后果的，个人会员以本人的名义独立承担所有法律责任；单位会员以单位名义承担所有法律责任，平台账号操作使用人及单位法定代表人承担连带责任。
        </p>
        <p>
          (4)如您涉嫌违反有关法律或本协议，使平台遭受损失，或受到第三方索赔，或受到行政管理部门处罚，须赔偿平台相应损失及（或）发生的费用，包括但不限于调查取证费、公证费、律师费等。
        </p>
        <p>
          (5)平台发布的政策文件、解读内容、报告范文、统计数据、营销政策等信息随时都有可能发生变动，平台不作特别通知。由于平台信息量极其庞大，虽然平台会尽最大努力保证您所浏览信息的准确性，但受随时可能出台新政策等客观原因影响，平台显示的信息可能会有一定的滞后性或差错，对此情形您知悉并理解，且不得以此为由向平台或政沣公司主张任何权利。
        </p>
        <p>
          (6)当您在使用平台过程中，若发现任何问题或差错，应暂停使用，并即时通过在线客服留言、拨打服务热线、致电客户经理等方式联系平台核实，经平台核实并修正后再恢复使用。
        </p>
        <p>
          (7)通过平台项目估算器计算的结果，仅作为用户申报项目额度的参考，不代表用户申报项目的最终结果。项目估算器计算结果与申报项目最终结果之间存在的任何差异，均不构成您向平台或政沣公司主张任何权利的依据。
        </p>
        <p>
          (8)对于他方输入的，非平台自主发布的信息，平台概不承担任何法律责任，但是对于给您造成损失（凭有效证据并经公证）的，平台有责任协助您查实。
        </p>
        <h2>第五条 平台的服务说明</h2>
        <h2>1.合法使用</h2>
        <p>
          (1)您仅有权在法律规定及本协议约定的方式和范围内使用平台及其服务，并对在平台中或通过注册账号所进行的全部行为（包括但不限于内容制作或上传、信息发布或转发、言论发表等）独立承担全部法律责任。
        </p>
        <p>
          (2)您不得以任何目的，通过使用平台直接或间接地实施或教唆、帮助他方实施任何侵犯或可能侵犯平台及/或第三方知识产权及相关权利的行为。如您因使用平台服务违反法律规定或侵害他人合法权益而导致任何第三方提出索赔的，均由您独立承担全部法律责任。
        </p>
        <p>
          (3)您在使用平台服务时，承诺不将本服务用于任何违法、非法目的，包括但不限于：
        </p>
        <p>
          a.散布、传播、存储含有危害国家安全统一、破坏社会稳定、违反公序良俗、侮辱、诽谤、淫秽、赌博、暴力等违反国家法律法规的内容；
        </p>
        <p>
          b.散布、传播、存储含有侵害他人知识产权、商业秘密等合法权利的内容；
        </p>
        <p>c.散布、传播、存储广告信息及垃圾信息；</p>
        <p>
          d.散布、传播、上传、存储含有计算机病毒、木马、蠕虫的文件，或其它任何可能破坏他人计算机运行或他人财产的程序；
        </p>
        <p>
          e.散布、传播、存储、下载任何其他用户所传播的，但您明知或应知是侵犯他人合法权益的文件；
        </p>
        <p>f.法律、法规、规章和国家政策禁止的其它行为。</p>
        <p>
          您同意，若违反上述任何一项的规定或第三方向平台通知、举报用户将本服务用于以上非法目的，平台有权在不预先通知您的情况下立即删除或屏蔽相关内容，中止或终止向您提供服务且不退还已收取的任何费用。
        </p>
        <p>
          (4)用户不得（或委托第三方）对平台进行反向工程、反向编译或反汇编或借助平台及其服务发展与之有关的衍生产品、作品、服务、插件、外挂、兼容、互联等行为，否则平台有权追究用户的侵权责任。
        </p>
        <p>
          2.平台有权根据业务情况或系统客观情况，新增或更改平台各类产品或服务，变更情况将及时通过平台发布公告，除有另行规定，自公布之日起生效。
        </p>
        <p>
          3.电话、手机短信、传真及平台公告均为平台向用户传递信息的有效途径。对于已发出的消息均视为已向用户进行有效送达。
        </p>
        <h2>第六条 关于协议的变更、中止和终止</h2>
        <p>
          1.您同意，在认定您违反本协议的前提下，平台可终止向您提供部分或全部平台服务、冻结或注销您的账号。
        </p>
        <p>2.出现以下情况时，平台有权直接以注销账号的方式终止本协议：</p>
        <p>
          (1)平台终止向您提供服务后，您涉嫌再一次直接或间接或以他人名义注册为平台用户的；
        </p>
        <p>(2)您注册信息中的主要内容不真实、不准确、不完整或已失效的；</p>
        <p>(3)本协议变更时，您明示并通知平台不愿接受新服务协议的；</p>
        <p>(4)平台认为应当终止服务的其它情况。</p>
        <p>
          3.您可通过平台相关页面提示或联系客服注销您的账号，届时，您与平台基于本协议的合同关系即终止。您的账号被注销后，平台没有义务为您保留或向您披露您账号中的任何信息。
        </p>
        <p>4.您同意，您与平台的合同关系终止后，政沣公司仍享有下列权利：</p>
        <p>
          (1)保存您的注册信息及您使用平台服务期间上传、制作或发布的资料和信息等。
        </p>
        <p>
          (2)您在使用平台服务期间存在违法行为或违反本协议和/或规则的行为的，平台仍可依据本协议向您主张权利。
        </p>
        <p>
          (3)平台有权继续依法记录、保存平台上传、制作或发布的资料和信息，即便本协议已终止。
        </p>
        <p>
          5.政沣公司中止或终止向您提供平台服务时，对于您在服务中止或终止之前的操作行为，您应独立处理并完全承担相关责任及费用，确保平台免于因此产生任何损失或承担任何费用。
        </p>
        <p>
          6.您在平台上传、制作或发布的资料和信息，平台有权在中止或终止服务的同时删除。
        </p>
        <p>7.平台有权修订平台服务协议/交易规则，修改内容将在实施前公示。</p>
        <p>
          8.平台有权依据本协议对平台用户违反法律、法规的行为实施警示、中止或终止服务等措施并及时公示。
        </p>
        <p>
          9.知识产权权利人认为其知识产权受到侵害的，平台有权依据知识产权权利人提供的侵权证据，对平台用户采取冻结、删除、屏蔽、中止或终止服务等必要措施。
        </p>
        <p>第七条 知识产权</p>
        <p>
          1.平台由政沣公司独立自主开发完成。平台的著作权、商标权、专利权、商业秘密等知识产权、其它相关权利均独立归属政沣公司所有。平台以及与其相关的所有信息内容，包括但不限于：平台商标标识、网页/产品版面设计、界面设计、图标、色彩、文字表述、数据、代码、电子文档及其组合等，均受中华人民共和国著作权法、商标法、专利法、反不正当竞争法和相应的国际条约以及其它知识产权法律法规的保护。未经本平台及其他相关权利人同意，用户不得以任何方式或任何途径实施发布、复制、出版、修改等行为，不论是否以商业为目的。
        </p>
        <p>
          2.除法律另有强制性规定外，任何单位或个人不得以任何方式全部或部分复制、转载、引用、链接、抓取，或以其它方式获取平台的信息内容用于营利用途，否则，平台有权追究其法律责任。
        </p>
        <p>第八条 隐私政策</p>
        <p>
          1.本平台致力于保护您的隐私和信息安全。我们使用各种制度、安全技术和程序等措施来保护您的信息不被非法访问、篡改、披露或破坏。
        </p>
        <p>
          2.为向您提供各项服务，提升服务质量，优化服务体验，我们会在符合法律规定的情况下使用您的信息，并主要用于下列用途：
        </p>
        <p>
          (1)向您推荐您可能感兴趣的内容，包括但不限于向您发出产品和服务信息，或通过系统向您展示个性化的第三方推广信息，或在征得您同意的情况下与本平台的合作伙伴共享信息，以便他们向您发送有关产品和服务信息。如您不希望接收上述信息，可自行取消。
        </p>
        <p>
          (2)我们可能使用您的信息以验证身份，预防、发现、调查欺诈、危害安全、非法或违反关联方协议、政策或规则的行为，以保护您、其他平台用户、关联方的合法权益。
        </p>
        <p>(3)让您参与有关我们产品及服务的调查。</p>
        <p>
          3.您的隐私和信息安全是我们为您提供服务的重要部分，我们会遵循法律规定对您的信息承担保密义务。除以下情形外，我们不会将您的个人信息披露给第三方：
        </p>
        <p>(1)征得您的同意或授权；</p>
        <p>
          (2)根据法律法规的规定或行政或司法机构的要求，或为了维护社会公共利益的需要；
        </p>
        <p>(3)其它必要的、合法、合理的用途。</p>
        <p>
          4.我们会对平台服务的使用情况进行统计，并可能会与公众或第三方分享这些统计信息，以展示我们的产品或服务的整体使用趋势，但这些统计信息不包含您的任何身份识别信息。我们有权在剔除用户身份识别信息前提下，对用户数据库进行统计、分析、利用，且无须经过您的授权或同意。
        </p>
        <p>
          5.您在填写调查问卷或使用项目估算、智能推送等过程中录入平台的信息，如包含商业秘密或其它隐私信息，您应自行承担该信息可能被网络黑客恶意窃取、披露的风险，并不得因此向平台主张任何权利。您此前已通过网络平台、媒体、政府及其职能机构等向公众披露的信息，或依法规必须履行披露义务的信息，不视为商业秘密或其它隐私信息。
        </p>
        <p>第九条 其它约定</p>
        <p>
          1.本协议的订立、解释、履行及效力均受中华人民共和国法律保护。如发生本协议条款与法律相抵触时，则这些条款将完全按法律规定重新修订、解释，而其它条款继续有效。
        </p>
        <p>
          2.您确认，在完成注册程序或以平台允许的其它方式实际使用服务时，您应当具备中华人民共和国法律规定的民事行为能力，否则您及您的监护人应依法承担因此而导致的相应后果，且平台有权终止向您提供服务并注销您的账号。如您代表一家单位或其它法律主体在平台注册，您有义务使该单位或该法律主体受本协议的约束。
        </p>
        <p>
          3.除平台存在过错外，您应对您的所有行为负责，包括但不限于在线签署各类协议，上传、制作或发布资料和信息，提供、订购服务及披露信息等。
        </p>
        <p>
          4.如您就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向政沣公司工商注册地法院提起诉讼。
        </p>
        <p>
          5.本协议在您点击“已阅读并同意”，或填写手机号码并获取验证码，或实际操作使用平台时立即生效。
        </p>
        <p>
          6.根据本协议制定的各项产品与服务的实施细则，以及其它以平台名义发布的制度、办法、规定、公告等均属本协议不可分割的部分。
        </p>
        <p>7.本协议的最终解释权归广东政沣云计算有限公司所有。</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { request } from "@/network";
import {
  getnoreadmessage,
  // getMemberId
} from "@/assets/public/utils/token";
export default {
  name: "loginRightRegister",
  data() {
    return {
      keepWake: false,
      keepWaketext: false,
      registerForm: {
        // name: "",
        // phoneNumber: "",
        // code: "",
        password: "",
        passwordAgain: "",
      },
      registerFormRules: {
        // name: [
        //   { required: true, message: "请输入联系人姓名", trigger: "blur" },
        // ],
        // phoneNumber: [
        //   {
        //     required: true,
        //     pattern: "^[1][3,4,5,6,7,8,9][0-9]{9}$",
        //     message: "请输入正确的11位手机号码",
        //     trigger: "blur",
        //   },
        // ],
        // code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        password: [
          {
            required: true,
            validator: this.testPassWord,
            message: "请输入新密码（6-20位，须含数字和字母）",
            trigger: "blur",
          },
        ],
        passwordAgain: [
          {
            required: true,
            validator: this.testPassWordAgain,
            trigger: "blur",
          },
        ],
      },
      button_label: "获取验证码",
      disabled: false,
      isNotAgainAble: true,

      dialogVisible: false,
    };
  },
  methods: {
    quickRegis() {
      if (!this.keepWake) {
        this.keepWaketext = true;
        return;
      }
      let params = this.$route.query;

      params["pwd"]=this.registerForm.password;

      this.$refs.registerFormRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await request({
            method: "POST",
            url: "/pcp/setMember",
            data: params,
          });
          if (res.code !== 200) return this.$message.error(res.message);
          this.$message.success("绑定成功");
          this.$store.commit("login/setUser", [res.data, true]);
          this.$router.push("/index");
          this.getLoginInfo();
        }
      });
    },
    async getLoginInfo() {
      const { data: res } = await request({
        method: "POST",
        url: "/pcp/getLoginInfo",
      });
      if (res.code !== 200) return this.$message.error("获取用户信息失败");
      getnoreadmessage(request, res.data.id).then((resMessage) => {
        localStorage.setItem("notRead", resMessage);
        this.$store.commit("personal/setNotRead", resMessage);
        this.$store.commit("personal/setImgUrl", res.data.avatar);
      });
    },

    restempty(fval, value) {
      this[fval][value] = this[fval][value].replace(/[, ]/g, "");
    },
    testPassWord(rule, value, callback) {
      const testPaw = /^(?!\D+$)(?![^a-zA-Z]+$)\S{6,20}$/;
      if (!testPaw.test(value)) {
        return callback(new Error("请输入新密码（6-20位，须含数字和字母）"));
      }
      this.isNotAgainAble = false;
      callback();
    },
    testPassWordAgain(rule, value, callback) {
      if (value !== this.registerForm.password) {
        return callback(new Error("两次输入的密码不一致，请重新输入"));
      }
      callback();
    },
  },
  watch: {
    keepWake(newVal) {
      if (newVal) this.keepWaketext = false;
    },
  },
};
</script>

<style scoped lang="less">
/*总体样式*/
.register {
  // height: 626px;
  width: 330px;
  margin-left: 139px;
  padding: 40px 0;
}
/*右侧头部样式*/
.login-right-top {
  margin-top: 40px;
  flex-direction: column;
  align-items: flex-start;
}
.login-right-top span:nth-of-type(1) {
  color: rgba(16, 16, 16, 100);
  font-size: 24px;
  flex: 1;
  font-weight: 600;
  margin-bottom: 10px;
}
.login-right-top span:nth-of-type(2) {
  color: rgba(230, 0, 18, 100);
  font-size: 14px;
}
.login-right-top a {
  margin-right: 48px;
  font-size: 14px;
  color: #156ED0;
  cursor: pointer;
}
.login-right-top div:before {
  content: "";
  display: inline-block;
  margin-right: 4px;
  width: 16px;
  height: 16px;
  background: url("~@/assets/pc/images/login/login-code.png");
}
/*右侧表单样式*/
.login-form {
  margin-top: 30px;
  padding-bottom: 30px;
}
.login-form {
  /deep/ .el-input_inner {
    -webkit-text-security: disc !important;
  }
}
.el-input {
  width: 100%;
  font-size: 16px;
  color: #000000;
}
.el-form-item:nth-child(n-1) {
  margin-bottom: 20px;
}
.el-form-item:nth-child(3) .el-input {
  width: 210px;
  margin-right: 10px;
}
.el-form-item:nth-child(3) {
  position: relative;
}
.el-form-item:nth-child(3) .el-button {
  margin-left: 0;
}
/*由于“获取验证码”按钮在强制设置高度之后样式会错位（在此处就是强行往下走了1.5px），所以得处理*/
.get-ver {
  position: absolute;
}
.el-form-item:nth-child(5) {
  margin-bottom: 0;
}
.el-form-item:nth-child(6) {
  margin-bottom: 27px;
}
.login-form /deep/ .el-input > input {
  height: 50px;
  padding-left: 12px;
  font-size: 16px;
  color: #8d8d8d;
}
.submit-btn {
  margin-bottom: 10px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background-color: #156ED0;
}
.check-box-click {
  background-color: #e5e5e5;
  border: none;
}
.submit-btn-continue {
  width: 100%;
  margin-left: 0;
}
.get-ver {
  width: 100px;
  height: 50px;
  padding: 12px 0;
  font-size: 14px;
  border: 1px solid #156ED0;
  color: #156ED0;
}
/* checkbox选中后颜色不变,让文字与选择框相距5px*/
#checkbox-form /deep/ .el-checkbox__label {
  color: #000000;
  padding-left: 5px;
  margin-top: 15px;
}
#checkbox-form a {
  color: #156ED0;
  font-size: 14px;
}
/*消除checkbox高度*/
#checkbox-form /deep/ .el-form-item__content {
  line-height: 0px;
}
/deep/ .el-form-item__content {
  line-height: 20px;
}
</style>
